/* eslint-disable camelcase */
import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";

// components
import searchIcon from "./assets/lupa.svg";
import moreIcon from "./assets/more.svg";
import categoryIcon from "./assets/categoryicon.svg";
import { ContentWrapper } from "../../styles/globals/grid";

import {
  Container,
  ResourceContainer,
  CategoriesWrapper,
  CategoriesTitle,
  CategoriesList,
  CategoryItem,
  ResourcesWrapper,
  GeneralContainer,
  ResourcesList,
  ItemContainer,
  InputSearch,
  SearchContainer,
  CategoryContainer,
  SearchImg,
  TitleContainer,
  CategoryTitle,
  HorizontalLine,
  CategoryIconContainer,
  CategoryIcon,
  Line,
  ViewMoreContainer,
  ViewMoreIcon,
  ViewMoreButton,
  SideBar,
} from "./BlogIndex.styles";

import renderCards from "../ResourcesCard/ResourcesCard";

const BlogIndexComponent = (data) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Media Library");

  const categories = data?.data?.cms?.blogCategories?.data || [];
  const articles = data?.data?.cms?.blogArticles?.data || [];

  const searchMatch = (article) => {
    const { Article_Title, Article_Subtitle } = article.attributes;
    const matchTitle = Article_Title.replace(/ /g, "")
      .toLowerCase()
      .includes(inputValue.replace(/ /g, "").toLowerCase());
    const matchSubtitle = Article_Subtitle.replace(/ /g, "")
      .toLowerCase()
      .includes(inputValue.replace(/ /g, "").toLowerCase());
    return matchTitle || matchSubtitle;
  };

  const ArticlesToRender = [];
  categories.forEach((category) => {
    const categoryArticles = articles.filter((Article) => {
      const articleCategoriesTitles = Article.attributes.Article_Category.data.map(
        (category) => {
          return category.attributes.Category_Title;
        }
      );
      return (
        articleCategoriesTitles.includes(category.attributes.Category_Title) &&
        searchMatch(Article)
      );
    });
    ArticlesToRender.push({
      category: category.attributes.Category_Title,
      articles: categoryArticles,
    });
  });

  const renderCategories = () => {
    let items = ArticlesToRender;
    items = [
      {
        category: "All Media Library",
        all: true,
      },
      ...items,
    ];

    return items.map((group) => {
      const isActive = selectedCategory === group.category;
      if (group?.articles?.length > 0 || group.all) {
        return (
          <ItemContainer>
            <CategoryItem
              isActive={isActive}
              key={group.category}
              onClick={() => setSelectedCategory(group.category)}
            >
              {group.category}
            </CategoryItem>
          </ItemContainer>
        );
      }
    });
  };

  return (
    <Container>
      <ContentWrapper>
        <GeneralContainer>
          <SideBar>
            <ResourceContainer>
              <SearchContainer>
                <InputSearch
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  placeholder="Search"
                />
                <SearchImg src={searchIcon} />
              </SearchContainer>

              {/* Categories List */}
              <CategoriesWrapper>
                <CategoryIconContainer>
                  <CategoryIcon src={categoryIcon} />
                  <CategoriesTitle>Categories</CategoriesTitle>
                </CategoryIconContainer>
                <Line />
                <CategoriesList>{renderCategories()}</CategoriesList>
              </CategoriesWrapper>

              {/* Article Cards List */}
            </ResourceContainer>
          </SideBar>
          <ResourcesWrapper>
            <>
              {ArticlesToRender.map((group) => {
                if (
                  selectedCategory === group.category ||
                  (selectedCategory === "All Media Library" &&
                    group.articles.length)
                )
                  return (
                    <CategoryContainer>
                      <TitleContainer>
                        <CategoryTitle>{group.category}</CategoryTitle>
                        <HorizontalLine />
                      </TitleContainer>
                      {selectedCategory !== "All Media Library" && (
                        <ResourcesList showAll={false}>
                          {group.articles.map((article) => {
                            return renderCards(article);
                          })}
                        </ResourcesList>
                      )}
                      {selectedCategory === "All Media Library" && (
                        <ResourcesList showAll>
                          {group.articles.slice(0, 3).map((article) => {
                            return renderCards(article);
                          })}
                        </ResourcesList>
                      )}
                      {selectedCategory === "All Media Library" && (
                        <ViewMoreContainer
                          onClick={() => setSelectedCategory(group.category)}
                        >
                          <ViewMoreButton>View more</ViewMoreButton>
                          <ViewMoreIcon src={moreIcon} />
                        </ViewMoreContainer>
                      )}
                    </CategoryContainer>
                  );
              })}
            </>
          </ResourcesWrapper>
        </GeneralContainer>
      </ContentWrapper>
    </Container>
  );
};

const BlogIndex = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            blogArticles(pagination: { limit: -1 }) {
              data {
                attributes {
                  Article_Category {
                    data {
                      attributes {
                        Category_Title
                      }
                    }
                  }
                  Article_Subtitle
                  Article_Title
                  Content_Tag
                  Article_Thumbnail {
                    data {
                      attributes {
                        alternativeText
                        url
                        mime
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          cms {
            blogCategories(pagination: { limit: -1 }) {
              data {
                attributes {
                  Category_Title
                }
              }
            }
          }
        }
      `}
      render={(data) => <BlogIndexComponent data={data} />}
    />
  );
};

export default BlogIndex;
