import styled from "styled-components";

import { COLORS } from "../globals/colors";
import {
  DESKTOP_1920,
  DESKTOP_1440,
  DESKTOP_1366,
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_850,
  TABLET_800,
  TABLET_768,
  TABLET_740,
  MOBILE_460,
  MOBILE_380,
  MOBILE_320,
  TABLET_600,
  DESKTOP_1500,
} from "../globals/sizes";

export const HeroContainer = styled.div`
  // margin-top: 90px;
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
  overflow: hidden;
  background: #ececec;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${TABLET_768}px) {
    background: white;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const PhoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 15px;
  align-items: center;

  @media (max-width: ${TABLET_850}px) {
    margin-bottom: 45px;
  }

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const HeroPicture = styled.picture`
  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
  & > img,
  & > source {
    width: 100%;
    margin-right: 0%;
    // height: 100%;
    object-fit: cover;
  }
`;

export const ContactUsPhone = styled.a`
  width: fit-content;
  padding: 14px 45px;
  border-radius: 25px;
  font: 400 18px/21px Roboto;
  color: ${COLORS.white};
  background-color: ${COLORS.vermilion};
  border: 0;
  word-wrap: break-word;
  display: ${(props) => (props.mobile ? "none" : "flex")};
  &:hover {
    filter: grayscale(50%);
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 16px;
  }
  @media (max-width: ${TABLET_768}px) {
    align-self: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

export const TabletDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 100% 95%/cover no-repeat`
      : `background: purple`};
  @media (max-width: ${TABLET_768}px) {
    height: 400px;
    display: block;
    background-position: 80%;
  }
  @media (max-width: 600px) {
    height: 330px;
    background-position: 80%;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 100% 95%/cover no-repeat`
      : `purple`};
  
  @media (max-width: ${TABLET_768}px) {
    display: block;
    background-position: 43% 45%;
    height: 390px;
  }
`;

export const MobileDivImageContainter = styled.div`
  width: 100%;
  height: 350px;
  overflow: hidden;
  display: none;
  @media (max-width: ${TABLET_768}px) {
    background-position: 43% 45%;
    display: block;
  }
`;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
    margin: 0;
    flex: 1;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }
`;

export const HeroTextDiv = styled.div`
  width: 85%;
  padding: 50px 120px;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: 1600px) {
    width: 90%;
  }

  @media (max-width: ${TABLET_992}px) {
    padding: 25px 40px 0;
    box-sizing: border-box;
  }

  @media (max-width: ${TABLET_768}px) {
    padding: 50px 40px 0px 40px;
    box-sizing: border-box;
  }

  @media (max-width: ${TABLET_600}px) {
    margin: 30px 30px 0;
    padding: 0;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin: 30px 25px 0;
    padding: 0;
  }


  @media (max-width: ${MOBILE_320}px) {
    margin: 20px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  width: 12em;
  margin: 40px 0 20px;
  color: ${COLORS.redTitle};
  text-align: left;
  font: normal normal 500 57px/60px Roboto;
  color: #E0281A;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }
  /* @media (max-width: ${DESKTOP_1200}px) {
    width: 30%;
    font: 500 calc(3vw)/ calc(3.5vw) "Roboto";
  } */
  @media (max-width: ${TABLET_992}px) {
    margin: 0 0 10px;
  } 
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    margin: 14px 0;
    font: normal normal normal 47px/54px Roboto;
    text-align: center;
    align-items: center;
  }

  @media (max-width: ${TABLET_600}px) {
    text-align: left;
  }
`;

export const Paragraph = styled.p`
  margin-top: 20px;
  color: ${COLORS.grayText};
  font: normal normal normal 26px/34px Roboto;
  width: 45%;

  @media (max-width: ${DESKTOP_1200}px) {
    width: 60%;
    font: normal normal normal 24px/32px Roboto;
  }

  @media (max-width: ${TABLET_992}px) {
    font: normal normal normal 18px/26px Roboto;
  }
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    margin: 0 0 20px 0;
    font: normal normal normal 20px/28px Roboto;
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${TABLET_600}px) {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const TwoDivsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 1500px;
  width: 100%;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  height: 500px;
  width: 50%;
  @media (max-width: ${DESKTOP_1440}px) {
    height: 470px;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    height: 350px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    height: 330px;
  }
  @media (max-width: ${TABLET_992}px) {
    height: 400px;
  }
  @media (max-width: 900px) {
    height: 390px;
  }
  @media (max-width: 850px) {
    height: 420px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 488px;
  }
  @media (max-width: ${TABLET_740}px) {
    height: 400px;
  }
  @media (max-width: 640px) {
    height: 350px;
  }
  @media (max-width: 550px) {
    height: 300px;
  }
  @media (max-width: ${MOBILE_460}px) {
    height: 250px;
  }
  @media (max-width: ${MOBILE_380}px) {
    height: 200px;
  }
`;

export const ImageContent = styled.picture`
  & > img,
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: ${DESKTOP_1200}px) {
      object-position: 20%;
    }
    @media (max-width: ${DESKTOP_1024}px) {
      object-position: 40%;
    }
    @media (max-width: ${TABLET_992}px) {
      object-position: 15%;
    }
    @media (max-width: 900px) {
      object-position: 25%;
    }
    @media (max-width: 850px) {
      object-position: 0%;
    }
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  background: transparent linear-gradient(270deg, #e01a73 0%, #e0281a 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 70px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0;
  }
`;

export const TextContent = styled.div`
  width: 80%;
  margin: auto 0;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
`;
